html,
body {
  margin: 0;
  padding: 0;
}

body {
  background-color: rgba(253, 253, 253, 0.517);
  --font-family-sans-serif: Open Sans, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

.container {
  color: rgb(43, 43, 43);
  display: grid;
  align-items: center;
  column-gap: 5px;
  grid-template-columns: 30% 30% 30%;
  grid-auto-rows: auto;
  width: 100%;
  max-width: 100%;
}

.card {
  text-align: center;
  font-family: Open Sans, sans-serif;
  text-transform: uppercase;
  display: inline-block;
  background-color: transparent;
  border: 0;
}

.card .title {
  font-size: 2em;
  color: rgb(125, 125, 125);
  font-weight: 600;
}

.s {
  margin-right: 10px;
}

.card p {
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
  color: rgb(62, 62, 62);
}

.card .title {
  margin-bottom: 10px;
  display: block;
}
.card .subtitle {
  font-size: 1em;
  color: rgb(125, 125, 125);
  margin-top: -15px;
  margin-bottom: 10px;
  display: block;
}

.card .totals {
  margin-bottom: 5px;
}

.card .totals {
  font-size: 8em;
}

.sales {
  background-color: rgb(209, 235, 209);
}

.cancellations {
  background-color: rgb(241, 213, 213);
}

.extra {
  background-color: rgb(85, 85, 85);
}

.extra p,
.extra .numbers,
.extra .totals {
  font-size: 1.5em;
  color: rgb(214, 214, 214);
}

.extra .numbers {
  display: inline-block;
  margin-left: 50px;
}

.extra p {
  margin-bottom: 5px;
  margin-top: 5px;
}

.container.sales {
  grid-template-columns: 24% 24% 15% 28%;
}

.totals.sales {
  margin-bottom: -20px;
  font-size: 8em;
}

.goal {
  font-size: 38px;
  color: rgb(192, 192, 192) !important;
  margin-top: -10px !important;
}

.card .revenue {
  font-size: 4em;
  margin-bottom: -15px;
}

#main-iframe {
  display: block !important;
}

@media (min-width: 1500px) {
  .card .numbers {
    font-size: 5em;
  }
  .card .title {
    font-size: 1.5em;
  }
  .card .totals {
    font-size: 7em;
  }
  .card .subtitle {
    font-size: 0.8em;
  }
  .extra p,
  .extra .numbers,
  .extra .totals {
    font-size: 1.5em;
  }
  .totals.sales {
    margin-bottom: -20px;
    font-size: 8em;
  }
  .goal {
    font-size: 38px;
  }

  .card .revenue {
    font-size: 4em;
    margin-bottom: -15px;
  }
}

@media (max-width: 1500px) {
  .card .numbers {
    font-size: 4em;
  }
  .card .title {
    font-size: 1.5em;
  }
  .card .subtitle {
    font-size: 0.8em;
  }
  .card .totals {
    font-size: 6em;
  }
  .extra p,
  .extra .numbers,
  .extra .totals {
    font-size: 1em;
  }
  .extra .numbers {
    margin-left: 30px;
  }
  .totals.sales {
    margin-bottom: -20px;
    font-size: 6em;
  }
  .goal {
    font-size: 25px;
  }

  .card .revenue {
    font-size: 3em;
    margin-bottom: -15px;
  }
}

@media (max-width: 1200px) {
  .card .numbers {
    font-size: 3em;
  }
  .card .title {
    font-size: 1em;
    margin-bottom: 15px;
  }
  .card .subtitle {
    font-size: 0.5em;
  }
  .card .totals {
    font-size: 5em;
  }
  .extra p,
  .extra .numbers,
  .extra .totals {
    font-size: 0.8em;
  }
  .extra .numbers {
    margin-left: 20px;
  }
}

@media (max-width: 800px) {
  .card .numbers {
    font-size: 2em;
    margin-bottom: 5px;
  }
  .card .title {
    font-size: 0.5em;
  }
  .card .totals {
    font-size: 3em;
  }
  .extra p,
  .extra .numbers,
  .extra .totals {
    font-size: 0.4em;
  }
  .extra .numbers {
    margin-left: 10px;
  }
  .totals.sales {
    margin-bottom: 5px;
    font-size: 2em;
  }
  .goal {
    font-size: 12px;
  }
  .container.sales .numbers {
    font-size: 2em;
  }
  .totals.sales {
    margin-bottom: -5px;
    font-size: 3em;
    margin-top: 0 !important;
  }

  .card .subtitle {
    margin-bottom: 1px;
  }
  .container.sales .numbers {
    margin: 0 !important;
  }
  .card .revenue {
    font-size: 3em;
    margin-bottom: -5px;
    margin-top: 0 !important;
  }
  #general-stats {
    font-size: 0.6rem;
  }
  .btn {
    padding: 5px;
    margin: 2px;
    font-size: 0.8rem;
  }
  .container,
  .container.sales,
  .container.cancellations {
    grid-template-columns: 100%;
  }

  .card {
    margin: 2px !important;
    padding: 0 !important;
  }
}
