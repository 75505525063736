/********************************************************************************
  Variables & Global Settings
********************************************************************************/
:root {
  --primary: #007bff;
  --secondary: #084073;
  --success: #41b96b;
  --warning: #f0ad4e;
  --menu-active: rgb(230, 157, 54);
  --error: #d9534f;
  --info: #4b8cdc;
  --background: #f8f9fa;
  --card-bg: #ffffff;
  --text-color: #212529;
  --muted-text: #6c757d;
  --border-radius: 4px;
  --shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  /* Primary Status Colors */
  --green-color: linear-gradient(
    135deg,
    #81c784,
    #66bb6a
  ); /* Moderate, visible green */
  --yellow-color: linear-gradient(
    135deg,
    #fff176,
    #ffee58
  ); /* Bright enough yellow */
  --red-color: linear-gradient(
    135deg,
    #e57373,
    #ef5350
  ); /* Vivid yet modern red */
  --orange-color: linear-gradient(
    135deg,
    #ffb74d,
    #ffa726
  ); /* Warm, engaging orange */
  --blue-color: linear-gradient(
    135deg,
    #64b5f6,
    #42a5f5
  ); /* Clear and modern blue */

  /* Modern Multicolor Palette (Set 1) */
  --multicolor-1: linear-gradient(135deg, #90caf9, #64b5f6);
  --multicolor-0: linear-gradient(135deg, #c3dfea, #b5def2);
  --multicolor-2: linear-gradient(135deg, #a5d6a7, #81c784);
  --multicolor-3: linear-gradient(135deg, #ffe082, #ffd54f);
  --multicolor-4: linear-gradient(135deg, #ce93d8, #ba68c8);
  --multicolor-5: linear-gradient(135deg, #b0bec5, #90a4ae);
  --multicolor-6: linear-gradient(135deg, #80deea, #4dd0e1);
  --multicolor-7: linear-gradient(135deg, #fff59d, #fff176);
  --multicolor-8: linear-gradient(135deg, #b39ddb, #9575cd);
  --multicolor-9: linear-gradient(135deg, #80cbc4, #4db6ac);

  /* Modern Multicolor Palette (Set 2) – Alternate Colors */
  --multicolor2-0: linear-gradient(135deg, #64b5f6, #42a5f5);
  --multicolor2-3: linear-gradient(135deg, #f06292, #ec407a);
  --multicolor2-1: linear-gradient(135deg, #81c784, #66bb6a);
  --multicolor2-2: linear-gradient(135deg, #ffd54f, #ffca28);
  --multicolor2-4: linear-gradient(135deg, #ba68c8, #ab47bc);
  --multicolor2-5: linear-gradient(135deg, #90a4ae, #78909c);
  --multicolor2-6: linear-gradient(135deg, #4dd0e1, #26c6da);
  --multicolor2-7: linear-gradient(135deg, #fff176, #ffee58);
  --multicolor2-8: linear-gradient(135deg, #9575cd, #7e57c2);
  --multicolor2-9: linear-gradient(135deg, #4db6ac, #26a69a);
}

/* Reset & Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  width: 100%;
}
body {
  font-family: "Roboto", sans-serif;
  background-color: var(--background);
  color: var(--text-color);
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 1.75rem; /* ~28px */
}
h2 {
  font-size: 1.5rem; /* ~24px */
}
h3,
.card-title {
  font-size: 1.25rem; /* ~20px */
}
p,
span,
a {
  font-size: 0.8rem; /* ~16px */
}

img,
.icon {
  image-rendering: crisp-edges;
}
a {
  text-decoration: none !important;
  color: var(--primary);
  transition: color 0.3s ease;
}
a:hover {
  color: var(--secondary);
}
html {
  backface-visibility: hidden;
}

/********************************************************************************
  Sidebar
********************************************************************************/
.sidebar {
  margin: 0;
  padding: 0;
  width: 220px;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  background-color: var(--card-bg);
  border-right: 1px solid #ddd;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 90000000;
}
.sidebar a,
.sidebar-collapsible {
  display: block;
  padding: 16px;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease;
  padding-left: 40px;
  text-align: left;
}
.sidebar a:hover,
.sidebar-collapsible:hover {
  background-color: #f0f0f0;
}
.sidebar a.is-active,
.sidebar-collapsible.is-active {
  font-weight: 600;
  color: var(--menu-active);
}
.sidebar a svg,
.sidebar-collapsible svg {
  margin-right: 10px;
  font-size: 17px;
}
.sidebar-collapsible {
  padding-left: 20px;
}
.separator {
  margin: 10px 0;
  padding: 10px 16px;
  text-align: center;
  border-bottom: 1px solid #eee;
  color: var(--muted-text);
  font-size: 15px;
}

/********************************************************************************
  Main Content & Layout
********************************************************************************/
.content,
.contentWOS {
  padding: 0;
  background-color: var(--background);
  min-height: 100vh;
  padding-left: 60px;
}
.row {
  min-height: 300px;
}
.col,
.chart-col-75,
.chart-col-25,
.chart-col-33,
.chart-col-100,
.chart-container-75-25,
.chart-container {
  margin: 4px;
  background-color: transparent;
  border-radius: var(--border-radius);
  text-align: left;
}
.chart-col {
  position: relative;
}

/********************************************************************************
  Tables
********************************************************************************/
#main-table {
  height: 75vh;
}
.dataTables_info {
  display: none !important;
}
#main-table_filter {
  margin-left: 10px;
  width: 100%;
  margin-bottom: 5px;
}
#main-table_filter label {
  width: 100%;
  display: block;
}
#main-table_filter input {
  background-color: #fff;
  margin-left: 20px;
  width: calc(100% - 150px);
}
#main-table td {
  vertical-align: top;
  padding: 10px 20px;
  border: 1px solid #eee;
}
#main-table p {
  margin: 0;
}
#main-table .even {
  background-color: #fafafa;
}
#main-table .even td {
  padding-top: 10px;
}

/* React Data Table Styles */
.rdt_TableHeadRow {
  background-color: transparent !important;
}
.rdt_TableBody .rdt_TableCell {
  cursor: pointer;
}

/********************************************************************************
  Headings & Typography
********************************************************************************/
h1 {
  color: var(--primary);
}
h2 {
  color: #6c6c6c;
  margin-bottom: 2px;
}
h4 {
  color: var(--muted-text);
}
h5 {
  color: var(--primary) !important;
}
.blue {
  color: var(--primary) !important;
}
.gray {
  color: #606a82 !important;
}
.red {
  color: var(--error) !important;
}
.medium {
  font-size: 28px;
}
.right-pad {
  margin-right: 10px;
}
.left-pad {
  margin-left: 10px;
}

/********************************************************************************
  Chart & Card Containers
********************************************************************************/
.chart-container {
  margin-top: 6px;
  background-color: var(--card-bg);
  padding: 20px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  overflow-x: hidden;
}
.chartContainer {
  background-color: transparent; /* Dark background */
  width: 100%;
  height: 300px;
  max-height: 300px;
  position: relative;
  color: rgb(220, 220, 220);
}
.smaller-chartContainer .chartContainer {
  height: 180px;
}
.tiny-chartContainer .chartContainer {
  height: 100px;
}
.chart-container .chartTitle,
.col-big .chartTitle,
.col-small .chartTitle,
.col-medium .chartTitle {
  height: 30px;
}
.chart-container h5,
.col-big .chartTitle h5,
.col-small .chartTitle h5,
.col-medium .chartTitle h5 {
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
}

/********************************************************************************
  Responsive Grid Layouts
********************************************************************************/
.chart-container-75-25 {
  display: grid;
  grid-template-columns: 79% 20%;
  gap: 10px;
}
.chart-container-33 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.chart-col-75 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.chart-col-100 {
  display: grid;
  grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
  gap: 1px;
}
.full-cols {
  grid-template-columns: 100%;
  padding: 5px;
  margin: 0;
}
.chart-col-25 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

/********************************************************************************
  Background & Absolute Elements
********************************************************************************/
.background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.background,
.background img {
  width: 100%;
  z-index: -5000;
  opacity: 0.3;
}
.content.absolute {
  background-color: transparent;
}

/********************************************************************************
  References, Legends & Misc
********************************************************************************/
.reference p {
  padding: 10px 20px;
  margin-bottom: 2px;
  margin-right: 4px;
  display: inline-block;
  font-size: 12px;
}
.reference .col {
  margin: 0;
}
.custom-table tr {
  padding-bottom: 5px;
  border-bottom: 1px solid gray;
}
.filter-container .css-b62m3t-container,
.filter-container .btn {
  display: inline-block;
  width: 100px;
  vertical-align: middle;
  margin: 10px;
}
.filter-container .css-b62m3t-container {
  width: calc(100% - 150px);
  max-width: 400px;
}

/********************************************************************************
  Form Tables & Inputs
********************************************************************************/
.form-table {
  width: 100%;
  margin: 0 auto;
  color: var(--muted-text);
}
.form-table th {
  font-weight: normal;
  width: 15%;
}
.form-table thead tr {
  background-color: #eef1f2;
}
.title-like-table {
  margin: 0;
  padding: 5px 10px 10px;
  background-color: #e6e6e6;
  line-height: 1;
  color: var(--muted-text);
}
.form-table input[type="text"],
.form-table input[type="number"] {
  border: 1px solid #e7e8e9;
  border-radius: 3px;
  text-align: right;
  color: gray;
  width: 100%;
}
.form-table input[type="text"]:focus {
  border-color: #d9dadb;
}
.form-table td {
  padding: 10px;
  width: 15%;
}

/********************************************************************************
  Progress Report
********************************************************************************/
.progress-report {
  margin: 30px auto;
  width: 1000px;
  font-size: 15px;
  color: #363636;
}
.progress-report table {
  width: 100%;
}
.progress-report h4 {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #aaa;
}
.progress-report p {
  margin-bottom: 0;
  text-align: left;
}
.progress-report td {
  padding: 10px;
  color: #4b4b4b;
  text-align: left;
}
.progress-report .border {
  border: 1px solid #aaa;
  border-radius: 3px;
}
.progress-report .nobottom {
  border-bottom: 0 !important;
}
.progress-report .notop {
  border-top: 0 !important;
}
.progress-report .col-big .chartTitle {
  height: auto;
}
.progress-report .col-big .chartTitle h5 {
  padding-bottom: 10px;
  margin-bottom: 2px;
  border-bottom: 1px solid #d1d1d1;
  color: var(--primary) !important;
}

/********************************************************************************
  CS Title & Legend
********************************************************************************/
.cs-title {
  margin-bottom: 18px;
  padding-bottom: 5px;
  border-bottom: 1px dotted #ebebeb;
  width: 100%;
  display: block;
  grid-column: 1 / -1;
  color: #5eccad;
  font-weight: bold;
  text-align: right;
  position: relative;
}
.cs-legend {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 10px;
  color: #c7c7c7;
}
.cs-legend .square {
  height: 14px;
  width: 14px;
  border: 1px solid #cecece;
  margin: 10px;
}
.cs-legend span {
  display: inline-block;
  vertical-align: middle;
}

/********************************************************************************
  Miscellaneous
********************************************************************************/
.all-done {
  position: relative;
  height: 12px;
  margin-top: -5px;
  margin-left: 2px;
}
.all-done-white {
  width: 30px;
  position: absolute;
  z-index: 10000;
  bottom: 100px;
  left: calc(50% + 12px);
}
.borderless {
  border: 0;
}

/********************************************************************************
  Stat Cards
********************************************************************************/
.stat-card {
  background-color: #64b5f6;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.3s ease;
  cursor: pointer;
}
.stat-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}
.stat-card h2 {
  color: #fff;
  font-size: 1.2rem; /* ~32px */
  margin: 0;
}
.stat-card h1 {
  color: #fff;
  text-align: center;
  font-size: clamp(2rem, 10vw, 6rem);
  line-height: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.stat-card svg {
  font-size: 15px !important;
}
.stat-card.success {
  border-left: 5px solid var(--success);
}
.stat-card.warning {
  border-left: 5px solid var(--warning);
}
.stat-card.error {
  border-left: 5px solid var(--error);
}
.stat-card.info {
  border-left: 5px solid var(--primary);
}

/* Flexbox and Vertical Alignment */
.chart-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/********************************************************************************
  Buttons
********************************************************************************/
.btn-primary {
  background-color: var(--primary);
  color: #fff;
  border: none;
  padding: 10px 16px;
  border-radius: 6px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  text-align: center;
}
.btn-primary:hover {
  background-color: var(--secondary);
  transform: translateY(-2px);
}
.btn-light {
  background-color: #f8f9fa;
  color: var(--text-color);
  border: 1px solid #ddd;
  padding: 10px 16px;
  border-radius: 6px;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}
.btn-light:hover {
  background-color: #e2e6ea;
  transform: translateY(-2px);
}
.btn-refresh {
  border-color: rgba(0, 0, 0, 0.15);
  background-color: #fff;
  color: var(--muted-text);
  font-size: 16px;
  padding: 10px;
  border-radius: 4px;
}
.btn-refresh:hover {
  background-color: #ccc;
}

/********************************************************************************
  Report Table
********************************************************************************/
.report-table {
  padding: 20px;
  border-radius: var(--border-radius);
  background-color: var(--card-bg);
  margin-top: 10px;
}
.report-row {
  width: 100%;
  display: block;
  padding: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.35);
}
.report-col {
  display: inline-block;
  color: #000;
  font-size: 14px;
}
.report-col a {
  color: #2c2c2c;
  font-weight: bold;
}
.report-header {
  margin-bottom: 3px;
  background-color: #f5f5f5;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px;
}

/********************************************************************************
  Menu Title & Sidebar Collapsible
********************************************************************************/
.menu-title {
  padding: 5px;
  text-align: left;
  color: black;
  padding: 10px;
  border-bottom: 1px solid rgb(236, 235, 235);
  margin-top: 1px;
}
.menu-title:first {
  margin-top: 0;
}
.menu-name {
  background-color: var(--primary);
  padding: 15px;
  color: #ffffff;
  text-align: center;
  margin-top: 0;
}
.sidebar-collapsible {
  background-color: transparent;
  border: 0;
  width: 100%;
}
.menu-separator {
  height: 20px;
  width: 100%;
  padding-bottom: 4px;
}
.gray-card {
  padding: 20px;
  border-radius: var(--border-radius);
  border: 1px solid #d6d6d6;
  margin: 5px;
}
.stats-btn {
  color: #1e73be;
  border: 1px solid #dfdfdf;
  margin: 5px;
}
.stats-btn.btn-primary {
  color: #fff;
}
.stats-btn:hover {
  background-color: #1e73be;
  color: #fff;
}
#currently-viewing,
#general-stats {
  color: #fff;
}

/********************************************************************************
  Loading Screen
********************************************************************************/
.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(135deg, #141e30, #243b55);
  color: #fff;
  font-family: "Arial", sans-serif;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}
.loading-logo {
  width: 25%;
  height: auto;
  margin-bottom: 20px;
  animation: pulse 1.5s infinite;
}
.loading-text {
  font-size: 2rem;
  animation: fade-in 2s infinite;
}
@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
@keyframes fade-in {
  0%,
  100% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
}

/********************************************************************************
  Select Container
********************************************************************************/
.select-container {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 300px;
  font-family: Arial, sans-serif;
}
.select-container select {
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 15px;
  border: 1px solid #ddd;
  border-radius: var(--border-radius);
  background-color: #fff;
  font-size: 16px;
  color: #333;
  outline: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}
.select-container select:focus {
  border-color: var(--primary);
  box-shadow: 0 0 6px rgba(11, 84, 147, 0.4);
}
.select-container::after {
  content: "▼";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 12px;
  color: #666;
}
.select-container select:disabled {
  background-color: #f5f5f5;
  color: #aaa;
  cursor: not-allowed;
}
.select-container select:hover:not(:disabled) {
  border-color: #aaa;
}

/********************************************************************************
  Sidebar Container & Toggle
********************************************************************************/
.sidebar-container {
  position: relative;
}
.sidebar {
  position: fixed;
  width: 250px;
  height: 100%;
  background: var(--card-bg);
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  transition: all 0.3s ease;
  z-index: 99999999999999999999 !important;
}
.sidebar.collapsed {
  width: 0;
  z-index: 90000000 !important;
}
.toggle-button {
  position: fixed;
  top: 50px;
  left: 250px;
  width: 40px;
  height: 100px;
  background: var(--primary);
  color: #fff;
  border: none;
  border-radius: 0 20px 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  z-index: 90000000;
}
.sidebar.collapsed + .toggle-button {
  left: 0;
}
.toggle-button:hover {
  background: var(--secondary);
}

/********************************************************************************
  Responsive Sidebar (Small Screens)
********************************************************************************/
@media screen and (max-width: 768px) {
  .sidebar {
    position: fixed;
  }
  .sidebar.collapsed {
    width: 0;
    overflow: hidden;
  }
  .toggle-button {
    width: 25px;
    left: 199px;
  }
  .loading-logo {
    width: 50%;
    height: auto;
    margin-bottom: 20px;
    animation: pulse 1.5s infinite;
  }
  .loading-text {
    font-size: 1rem;
    animation: fade-in 2s infinite;
  }
}

/* Mobile Topbar Sidebar */
@media screen and (max-width: 1200px) {
  .sidebar {
    position: fixed;
    height: 100%;
    top: 0;
    overflow-x: auto;
    white-space: nowrap;
  }
  .sidebar a,
  .sidebar-collapsible {
    padding: 10px;
    font-size: 14px;
    padding-left: 20px;
  }
  .content {
    margin-left: 0;
    margin-top: 0;
  }
}

/********************************************************************************
  Utility Classes
********************************************************************************/
.text-center {
  text-align: center;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}

/* Custom Scrollbar for WebKit Browsers */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: rgb(228, 228, 228);
  border-radius: 6px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgb(228, 228, 228);
  border-radius: 6px;
  border: 2px solid var(--background);
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(228, 228, 228);
}

/* Custom Scrollbar for Firefox */
* {
  scrollbar-width: thick;
  scrollbar-color: rgb(228, 228, 228);
}

.btn-reports {
  margin-bottom: 10px;
}

.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 8px;
  background: #ffffff; /* White background for the form */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.login-container input {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.login-container input:focus {
  border-color: #007bff; /* Blue border on focus */
  outline: none;
}

.login-container button {
  width: 100%;
  padding: 12px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #fff; /* White text */
  background-color: #007bff; /* Gargle blue */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.login-container button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: translateY(-2px);
}

.login-container button:disabled {
  background-color: #6c757d; /* Gray for disabled state */
  cursor: not-allowed;
}

.login-container .gsi-material-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #28a745; /* Gargle green */
  border-radius: 4px;
  padding: 12px;
  margin-top: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-container .gsi-material-button:hover {
  background-color: #218838; /* Darker green on hover */
}

.login-container .error-message {
  color: #dc3545; /* Red for error text */
  margin-top: 10px;
  font-size: 14px;
}

.gsi-material-button-content-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .login-container {
    padding: 15px;
    margin: 30px auto;
  }

  .login-container input {
    padding: 10px;
    font-size: 14px;
  }

  .login-container button {
    padding: 10px;
    font-size: 14px;
  }

  .login-container .gsi-material-button {
    padding: 10px;
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .login-container {
    margin: 20px auto;
    padding: 10px;
  }

  .login-container input {
    padding: 8px;
    font-size: 12px;
  }

  .login-container button {
    padding: 8px;
    font-size: 12px;
  }

  .login-container .gsi-material-button {
    padding: 8px;
    font-size: 12px;
  }
}

/* --- Sidebar Collapsed Overrides --- */

/* When collapsed, reduce sidebar width */
.sidebar.collapsed {
  width: 60px;
  transition: width 0.3s ease;
}

/* Hide text without extra wrappers by text-indenting the whole anchor.
   Then, reposition the SVG icon so it stays centered. */
.sidebar.collapsed a,
.sidebar.collapsed button {
  text-indent: -9999px; /* shift text off-screen */
  overflow: hidden;
  position: relative; /* establish positioning context for the icon */
  padding: 12px 0; /* adjust padding to center the icon vertically */
}

/* Restore the SVG icon's visibility and center it */
.sidebar.collapsed a svg,
.sidebar.collapsed button svg {
  text-indent: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}

.sidebar.collapsed .menu-title,
.sidebar.collapsed .sidebar-collapsible,
.sidebar.collapsed a[href="/"] {
  display: none;
}
/* --- Toggle Button Styling --- */

/* The toggle button to expand/collapse the sidebar */
.toggle-button {
  position: fixed;
  top: 50px;
  left: 250px; /* matches the expanded sidebar width */
  width: 40px;
  height: 100px;
  background: var(--primary);
  color: #fff;
  border: none;
  border-radius: 0 20px 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease, background-color 0.3s ease;
  z-index: 1000;
}

/* When sidebar is collapsed, move the toggle button to the right edge of the collapsed sidebar */
.sidebar.collapsed + .toggle-button {
  left: 60px;
}

/* Optional: Change toggle button background on hover */
.toggle-button:hover {
  background: var(--secondary);
}

.sidebar {
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.sidebar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

/* Right Panel Base */
.right-panel {
  position: fixed;
  top: 0;
  right: -100%; /* Hidden by default */
  height: 100vh;
  width: 50%; /* Desktop width */
  background-color: var(--card-bg, #fff);
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 90000000000000 !important;
  overflow-y: auto;
}

.right-panel.open {
  right: 0;
}

/* Close Button */
.right-panel-close {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 20px;
  color: var(--text-color, #212529);
  cursor: pointer;
  transition: color 0.3s ease;
}

.right-panel-close:hover {
  color: var(--primary, #0b5493);
}

/* Content Area */
.right-panel-content {
  padding: 20px;
  margin-top: 50px; /* Space for the close button */
  height: calc(100vh - 80px);
}

/* Open Button */
.open-right-panel-button {
  position: fixed;
  top: 50px;
  right: 0;
  width: 20px;
  height: 100px;
  background: var(--primary);
  color: #fff;
  border: none;
  border-radius: 20px 0 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease, background-color 0.3s ease;
  z-index: 1000;
  font-size: 10px;
}

.open-right-panel-button:hover {
  background: var(--secondary, #084073);
}

.open .open-right-panel-button {
  display: none;
}

/* Mobile: Full screen width */
@media (max-width: 768px) {
  .right-panel {
    width: 100%;
  }
  .chart-container {
    grid-template-columns: 100% !important;
  }
  .stat-card h2 {
    margin: 0 !important;
  }
  .chart-container .chart-col {
    height: auto !important;
  }
  .cs-legend {
    font-size: 10px;
    position: relative;
    margin-bottom: 0;
  }
  .filter-container {
    z-index: 900000000 !important;
  }
}

/* Container for the chat widget */
.chat-container {
  width: calc(100% - 20px);
  height: 100%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-family: Arial, sans-serif;
}

/* Message container */
.chat-box {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

/* General message styling */
.message {
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 5px;
  max-width: 80%;
  word-wrap: break-word;
}

/* User message styling */
.message.you {
  align-self: flex-end;
  color: rgb(0, 0, 0);
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 5px;
}

/* Bot message styling */
.message.bot {
  border: 0;
  align-self: flex-start;
}

/* Loading text styling */
.loading {
  font-style: italic;
  color: #999;
}

/* Input container */
.input-container {
  display: flex;
  border-top: 1px solid #ddd;
  padding: 5px;
}

/* Text input styling */
.input {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 5px;
  outline: none;
}

/* Send button styling */
.send-button {
  margin-left: 5px;
  padding: 8px 10px;
  border-radius: 5px;
  background-color: #4caf50;
  color: #fff;
  cursor: pointer;
  border: none;
  width: auto;
}

.ReactModal__Overlay {
  z-index: 9999999999;
  background-color: #131314a6 !important;
}

.ReactModal__Content {
  background-color: var(--background-color) !important;
  inset: 30% !important;
  text-align: center;
}

.ReactModal__Content button {
  margin: 8px;
}

.chat-message {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #e4e4e4;
}

.chat-message p {
  margin: 1em 0;
}

.chat-message strong {
  font-weight: bold;
}

.chat-message em {
  font-style: italic;
}

.chat-message ul {
  list-style-type: disc;
  margin-left: 1.5em;
}

.chat-message ol {
  list-style-type: decimal;
  margin-left: 1.5em;
}

.chat-message blockquote {
  border-left: 4px solid #ccc;
  padding-left: 1em;
  color: #555;
}

.chat-message a {
  color: #0366d6;
  text-decoration: none;
}

.chat-message a:hover {
  text-decoration: underline;
}

.chat-message code {
  background-color: #f6f8fa;
  padding: 0.2em 0.4em;
  border-radius: 4px;
  font-family: "Source Code Pro", monospace;
  font-size: 85%;
}

.chat-message pre {
  background-color: #f6f8fa;
  padding: 1em;
  border-radius: 6px;
  overflow: auto;
  margin: 1em 0;
}

.chat-message pre code {
  background-color: transparent;
  padding: 0;
}

.chat-message {
  color: #2e2e2e;
}
